import React, { useState, useEffect } from "react";
import productImg from "../assets/images/agri.jpg";
import { Row, Col, Container, Modal } from "react-bootstrap";
import "../assets/css/product.css";
import RazorpayPayment from "./Payment";
import Footer from "../components/Footer";
import { useLocation, useParams } from "react-router-dom";
import share from "../assets/images/share.png";
import linkImg from "../assets/images/logo/fi_link.png";
import facebookImg from "../assets/images/logo/facebook.png";
import whatsappImg from "../assets/images/logo/whatsapp.png";
import axios from "axios";
import Navbar from "../components/Navbar";

function ProductDetails() {
  const [showShareModal, setShowShareModal] = useState(false);
  const [productsDetails, setProductsDetails] = useState({});
  const [copySuccess, setCopySuccess] = useState("");
  const [showMore, setShowMore] = useState(false);

  const location = useLocation();
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    if (location.state && location.state.productsDetails) {
      setProductsDetails(location.state.productsDetails);
    } else {
      axios
        .get(`${baseUrl}/api/adv-product/${id}`)
        .then((response) => {
          setProductsDetails(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching product details:", error);
        });
    }
  }, [id, location.state, baseUrl]);

  const openShareModal = () => setShowShareModal(true);
  const closeShareModal = () => {
    setCopySuccess(false); 
    setShowShareModal(false);
  };

  const handleCopyLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setCopySuccess("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setCopySuccess("Failed to copy the link.");
      });
  };

  const getCurrentUrl = () => {
    return window.location.href;
  };

  const shareOnFacebook = () => {
    const url = getCurrentUrl();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const shareOnWhatsapp = () => {
    const url = getCurrentUrl();
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const extraDetailsContent = productsDetails?.extraDetails || "";
  const wordCount = extraDetailsContent.split(/\s+/).length;
  const isScrollable = wordCount > 80;

  return (
    <>
      <Navbar />
      <div className="mt-5">
        <Container fluid className="mt-5">
          <Row className="mt-5 d-flex justify-content-center align-items-start">
            <Col md={5} className="text-center mt-5 ">
            <div className="product-image-box">
            <img
                src={productsDetails.imageUrl || productImg}
                className=" img-fluid products-img"
                alt="Product"
              />
            </div>
             
            </Col>

            <Col
              md={5}
              className="d-flex flex-column justify-content-start mt-5"
            >
              <h4 className="product-title font-common mb-2">
                {productsDetails.title}
              </h4>
              <p className="text-wrap products-description mb-4 font-common">
                {productsDetails.description}
              </p>

              <div className="razorpay-container">
                <RazorpayPayment
                  amount={productsDetails.price}
                  name={productsDetails.title}
                  category={productsDetails.category}
                  productId={productsDetails._id}
                  buttonStyle={{ width: "300px" }}
                />
              </div>

             

              <div className="extra-details mt-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: showMore
                      ? extraDetailsContent
                      : extraDetailsContent.split(" ").slice(0, 80).join(" ") + " ",
                  }}
                />
                {isScrollable && (
                  <button
                    className="btn btn-link mt-2 p-0"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "View Less" : "View More"}
                  </button>
                )}
              </div>

              <div className="mt-3 share-container">
                <button
                  onClick={openShareModal}
                  className="btn btn-share btn-outline-white"
                >
                  <img
                    src={share}
                    className="mb-1"
                    style={{
                      width: "20px",
                      height: "20px",
                      filter: "brightness(0) invert(1)",
                    }}
                    alt="Share"
                  />
                  <span> Share</span>
                </button>
              </div>
            </Col>
          </Row>

          <Modal
            show={showShareModal}
            onHide={closeShareModal}
            dialogClassName="custom-modal-content"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "16px", color: "#0b6938" }}>
                Share this product
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row
                className="text-center d-flex justify-content-center position-relative"
                style={{ marginBottom: "20px" }} 
              >
                <Col
                  xs="auto"
                  className="d-flex flex-column align-items-center"
                >
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    role="button"
                    onClick={handleCopyLink}
                    style={{
                      width: "40px",
                      height: "40px",
                      border: "1px solid #0b6938",
                    }}
                  >
                    <img
                      src={linkImg}
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                      alt="Copy Link"
                    />
                  </div>
                </Col>

                <Col
                  xs="auto"
                  className="d-flex flex-column align-items-center"
                >
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    role="button"
                    onClick={shareOnFacebook}
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#0b6938",
                    }}
                  >
                    <img
                      src={facebookImg}
                      style={{ width: "30px", height: "30px" }}
                      alt="facebook"
                    />
                  </div>
                </Col>

                <Col
                  xs="auto"
                  className="d-flex flex-column align-items-center"
                >
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    role="button"
                    onClick={shareOnWhatsapp}
                    style={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#0b6938",
                    }}
                  >
                    <img
                      src={whatsappImg}
                      style={{ width: "30px", height: "30px" }}
                      alt="whatsapp"
                    />
                  </div>
                </Col>

                <div
                  className="copy-text"
                  style={{
                    opacity: copySuccess ? 1 : 0,
                    visibility: copySuccess ? "visible" : "hidden",
                  }}
                >
                  Link copied to clipboard!
                </div>
              </Row>
            </Modal.Body>
          </Modal>

          <Row className="mt-5 text-center d-flex justify-content-center">
            <Col md={3}>
              <div className="testimonial">
                <span className="stars">★★★★★</span>
                <p>
                  "खेत-ई सेवांचा आणि पीक मार्गदर्शकांचा वापर करून मी मोठ्या प्रमाणात पैसे वाचवले आणि भरपूर यश मिळवले. माझ्या मिरची शेतीला भरभराटीला नेण्यासाठी याचा खूप फायदा झाला."
                </p>
                <small> - संजय पाटील
                शिमला मिरची उत्पादक, कोल्हापूर (महाराष्ट्र)"</small>
              </div>
            </Col>
            <Col md={3}>
              <div className="testimonial">
                <span className="stars">★★★★★</span>
                <p>
                  "खेती ई सेवाओं और फसल गाइड का उपयोग करके, मेरी उपज में 30% की वृद्धि हुई और बाजार में उत्कृष्ट दर मिली। खेती ई टीम द्वारा प्रदान की गई जानकारी से मुझे बड़ी सफलता मिली."
                </p>
                <small>- संजीव बाहेती, टोमॅटो उत्पादक - हरदा, (मध्यप्रदेश)</small>
              </div>
            </Col>
            <Col md={3}>
              <div className="testimonial">
                <span className="stars">★★★★★</span>
                <p>
                  "खेती-ईच्या सेवांचा आणि पीक मार्गदर्शकांचा वापर करून मी माझ्या निविष्ठा व औषधांच्या खर्चाचे उत्तम व्यवस्थापन केले. पाण्याचे नियोजनही उत्कृष्टपणे करता आले. खेती-ईच्या टीमने दिलेल्या माहितीमुळे मला मोठे यश मिळाले."
                </p>
                <small>- तुषार धर्माधिकारी, काकडी उत्पादक, नाशिक (महाराष्ट्र)</small>
              </div>
            </Col>
          </Row>

          <Row
            className="mt-5 price-section d-flex justify-content-between align-items-center price-section-custom"
            style={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              backgroundColor: "#f9a825",
              zIndex: 1000,
              padding: "10px 20px",
              gap: "80px",
            }}
          >
            <Col className="d-flex justify-content-start justify-content-md-end">
              <span className="price">₹ {productsDetails.price}</span>
            </Col>
            <Col className="d-flex justify-content-end justify-content-md-start">
              <RazorpayPayment
                amount={productsDetails.price}
                name={productsDetails.title}
                category={productsDetails.category}
                productId={productsDetails._id}
                buttonStyle={{ width: "100px" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default ProductDetails;
