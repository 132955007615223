import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import khetElogo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [collapseState, setCollapseState] = useState({
    solutions: false,
    support: false,
    company: false,
    legal: false,
  });

  // Toggle collapse based on section
  const toggleCollapse = (section) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  // Set isMobile when component mounts
  React.useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobileView(); // Check on mount

    // Listen for window resize
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <footer id="footer" className="footer">
      <div className="footer-top">
        <Container>
          <Row className="d-flex align-item-center">
            <Col lg={4} md={4} sm={12} className="mb-5">
              <div className="single-footer f-about">
                <div className="logo">
                  <a href="/">
                    <img src={khetElogo} alt="KhetE Logo" />
                  </a>
                </div>
                <p>
                  Helping farmers with end-to-end support through consulting,
                  trainings and sales support
                </p>
                <ul className="social">
                  <li>
                    <a href="https://www.facebook.com/people/Khet-E/100089959036375/" target="_blank">
                      <i className="lni lni-facebook-filled"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://x.com/i/flow/login" target="_blank">
                      <i className="lni lni-twitter-original"></i>
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://www.instagram.com/khete.official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                      target="_blank"
                    >
                      <i className="lni lni-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/khet-e/?viewAsMember=true" target="_blank">
                      <i className="lni lni-linkedin-original"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@khet-e1322" target="_blank">
                      <i className="lni lni-youtube"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://in.pinterest.com/login/" target="_blank">
                      <i className="lni lni-pinterest"></i>
                    </a>
                  </li> */}
                </ul>

                <p>
                  <a
                    href="mailto:support@khet-e.in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    support@khet-e.in
                  </a>
                </p>
                <span className="phone">+91 91722 03896</span>
                <p className="copyright-text">
                  © {new Date().getFullYear()} KHET-E. All rights reserved.
                </p>
              </div>
            </Col>

            <Col lg={8} md={8} sm={12}>
              <Row>
                <Col lg={4} md={6} sm={12} className="mb-3">
                  <div className="single-footer f-link">
                    <h3
                      onClick={() => isMobile && toggleCollapse("legal")}
                      className="footer-heading"
                    >
                      Legal
                    </h3>
                    <ul
                      className={`footer-list ${
                        collapseState.legal || !isMobile ? "show" : "hide"
                      }`}
                    >
                      <li>
                        <a
                          onClick={() => handleNavigate("/terms-condition")}
                          style={{ cursor: "pointer" }}
                        >
                          Terms & Conditions
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => handleNavigate("/privacy-policy")}
                          style={{ cursor: "pointer" }}
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => handleNavigate("/cancellation-policy")}
                          style={{ cursor: "pointer" }}
                        >
                          Cancellation Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
