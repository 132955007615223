import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import "../assets/css/product.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RazorpayPayment from "./Payment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import share from "../assets/images/share.png";
import productBanner from "../assets/images/_banner image for product 1440 x 400/Need 1 banner image for product [1440 x 400 (Desktop) (3).png";
import linkImg from "../assets/images/logo/fi_link.png";
import facebookImg from "../assets/images/logo/facebook.png";
import whatsappImg from "../assets/images/logo/whatsapp.png";
import googlePlayImg from "../assets/images/google play.jpg";
import axios from "axios";
import Navbar from "../components/Navbar";

function Product() {
  const baseUrl = process.env.REACT_APP_BASEURL;

  const navigate = useNavigate();
  const { categoryId } = useParams();
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({
    categoryName: "",
    categoryImage: productBanner,
    categoryDescription: "",
  });
  const [showShareModal, setShowShareModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [charCount, setCharCount] = useState();
  const [enquiryData, setEnquiryData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const openInquiryModal = () => setShowInquiryModal(true);
  const closeInquiryModal = () => {
    setShowInquiryModal(false);
    resetForm();
  };
  const openThankYouModal = () => setShowThankYouModal(true);
  const closeThankYouModal = () => setShowThankYouModal(false);

  const handleSubmitInquiry = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/productCategory/enquiries/`,
        {
          ...enquiryData,
          productCategoryId: categoryId,
        }
      );

      if (response.status === 200) {
        closeInquiryModal();
        openThankYouModal();
      } else setErrorMessage(response.message);
    } catch (error) {
      console.error("Error submitting enquiry:", error);
      setErrorMessage("Failed to submit your enquiry. Please try again later.");
    }
  };

  useEffect(() => {
    const fetchProductsByCategory = async (categoryId) => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/adv-product/category/${categoryId}`
        );
        if (response.status === 200) {
          setProducts(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchCategoryDetails = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/product/categories/${categoryId}`
        );
        if (response.status === 200) {
          const category = response.data.data;
          setCategoryDetails({
            categoryName: category.name,
            categoryImage: category.imageUrl || productBanner,
            categoryDescription: category.description,
          });
        }
      } catch (error) {
        console.error("Error fetching category details:", error);
      }
    };

    if (location.state && location.state.categoryDescription) {
      setProducts(location.state.products);
      setCategoryDetails({
        categoryName: location.state.categoryName,
        categoryImage: location.state.categoryImage || productBanner,
        categoryDescription: location.state.categoryDescription,
      });
    } else {
      fetchProductsByCategory(categoryId);
      fetchCategoryDetails();
    }
  }, [categoryId, location.state, baseUrl]);

  const fetchProductsDetails = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/api/adv-product/${id}`);
      if (response.status === 200 && response.data.data) {
        navigate(`/products/products-detail/${id}`, {
          state: { productsDetails: response.data.data },
        });
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const openShareModal = () => setShowShareModal(true);
  const closeShareModal = () => {
    setCopySuccess(false);
    setShowShareModal(false);
  };

  const handleCopyLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setCopySuccess("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setCopySuccess("Failed to copy the link.");
      });
  };

  const getCurrentUrl = () => window.location.href;

  const shareOnFacebook = () => {
    const url = getCurrentUrl();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const shareOnWhatsapp = () => {
    const url = getCurrentUrl();
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
      "_blank"
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "message" && value.length <= 500) {
      setEnquiryData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setCharCount(value.length);
    } else if (name !== "message") {
      setEnquiryData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const resetForm = () => {
    setEnquiryData({
      name: "",
      phoneNumber: "",
      email: "",
      message: "",
    });
    setPhoneError("");
    setErrorMessage("");
    setCharCount(0);
  };

  return (
    <>
      <Navbar />
      <div>
        <Container className="my-5">
          <Row className="text-center mt-5">
            <Col className="text-center mt-5">
              <img
                src={categoryDetails.categoryImage}
                className="mb-4 img-fluid products-image"
                alt={categoryDetails.categoryName || "Product"}
              />
            </Col>
          </Row>
          <Row className="text-center ">
            <Col>
              <h4 className="product-title">{categoryDetails.categoryName}</h4>
              <p className="product-description text-center mt-4">
                {categoryDetails.categoryDescription}
              </p>
            </Col>
          </Row>

          <div
            className="scrolling-wrapper"
            style={{ overflowX: "auto", whiteSpace: "nowrap" }}
          >
            <Row className="d-flex flex-nowrap  product-cards">
              {products.map((product) => (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2}
                  className="mt-2"
                  key={product._id}
                  style={{ display: "inline-block" }}
                >
                  <Card
                    className=" border-0 text-center"
                    style={{
                      width: "100%",
                      height: "100%",
                      marginRight: "15px",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <Card.Body
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <img
                          src={
                            product.imageUrl ||
                            require("../assets/images/agri.jpg")
                          }
                          alt={product.title}
                          className="mb-2"
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                          }}
                          onClick={() => fetchProductsDetails(product._id)}
                        />
                        <div
                          className="mb-1 text-wrap"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            maxHeight: "2.4em",
                            lineHeight: "1.2em",
                            fontSize: "13px",
                          }}
                        >
                          <span>{product.title}</span>
                        </div>
                        <div
                          className="mb-3 mt-3 text-wrap"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            maxHeight: "2.4em",
                            lineHeight: "1.2em",
                            fontSize: "12px",
                          }}
                        >
                          <span>
                            {product.description.split(" ").length > 8
                              ? product.description
                                  .split(" ")
                                  .slice(0, 8)
                                  .join(" ") + "..."
                              : product.description}
                          </span>
                        </div>
                      </div>
                      <div>
                        <RazorpayPayment
                          amount={product.price}
                          name={product.title}
                          category={product.category}
                          productId={product._id}
                        />
                        <Card.Text>
                          <a
                            onClick={() => fetchProductsDetails(product._id)}
                            className=" explore text-decoration-none mt-2"
                          >
                            View More
                          </a>
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <Row className="mt-4 mb-5">
            <Col>
              <div className="text-center d-flex align-items-center justify-content-center flex-column">
                <div className="d-flex align-items-center mt-3">
                  <button
                    onClick={openShareModal}
                    className="btn btn-share btn-outline-white ms-1"
                  >
                    <img
                      src={share}
                      className="mb-1"
                      style={{
                        width: "20px",
                        height: "20px",
                        filter: "brightness(0) invert(1)",
                      }}
                      alt="Share"
                    />
                    <span> Share</span>
                  </button>

                  {categoryDetails.categoryName ===
                    "Farming As A Service (FAAS)" && (
                    <button
                      onClick={openInquiryModal}
                      className="btn btn-inquiry ms-3"
                      style={{
                        backgroundColor: "#0b6938",
                        color: "#fff",
                        border: "none",
                        padding: "8px 16px",
                        borderRadius: "4px",
                      }}
                    >
                      Inquiry Now
                    </button>
                  )}
                </div>

                {categoryDetails.categoryName === "Sell your Produce" && (
                  <div className="mt-3">
                    <p style={{ marginBottom: "8px", fontWeight: "bold" }}>
                      Get the app
                    </p>
                    <a
                      href="https://play.google.com/store/apps/details?id=in.khete.kheteApp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={googlePlayImg}
                        alt="Get it on Google Play"
                        style={{ width: "135px", height: "50px" }}
                      />
                    </a>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />

      {/* Share Modal */}
      <Modal
        show={showShareModal}
        onHide={closeShareModal}
        dialogClassName="custom-modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px", color: "#0b6938" }}>
            Share this product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row
            className="text-center d-flex justify-content-center position-relative"
            style={{ marginBottom: "20px" }}
          >
            <Col xs="auto" className="d-flex flex-column align-items-center">
              <div
                className="rounded-circle d-flex align-items-center justify-content-center"
                role="button"
                onClick={handleCopyLink}
                style={{
                  width: "40px",
                  height: "40px",
                  border: "1px solid #0b6938",
                }}
              >
                <img
                  src={linkImg}
                  style={{ width: "25px", height: "25px" }}
                  alt="Copy Link"
                />
              </div>
            </Col>

            <Col xs="auto" className="d-flex flex-column align-items-center">
              <div
                className="rounded-circle d-flex align-items-center justify-content-center"
                role="button"
                onClick={shareOnFacebook}
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#0b6938",
                }}
              >
                <img
                  src={facebookImg}
                  style={{ width: "30px", height: "30px" }}
                  alt="facebook"
                />
              </div>
            </Col>

            <Col xs="auto" className="d-flex flex-column align-items-center">
              <div
                className="rounded-circle d-flex align-items-center justify-content-center"
                role="button"
                onClick={shareOnWhatsapp}
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#0b6938",
                }}
              >
                <img
                  src={whatsappImg}
                  style={{ width: "30px", height: "30px" }}
                  alt="whatsapp"
                />
              </div>
            </Col>

            <div
              className="copy-text"
              style={{
                opacity: copySuccess ? 1 : 0,
                visibility: copySuccess ? "visible" : "hidden",
              }}
            >
              Link copied to clipboard!
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Inquiry Modal */}
      <Modal show={showInquiryModal} onHide={closeInquiryModal}>
        <Modal.Header closeButton>
          <Modal.Title>Inquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={enquiryData.name}
                onChange={handleInputChange}
                placeholder="Enter your name"
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={enquiryData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber" className="mb-3">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={enquiryData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
                maxLength={10}
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={enquiryData.message}
                onChange={handleInputChange}
                rows={3}
                placeholder="Enter your message"
              />
              <div
                style={{
                  textAlign: "right",
                  fontSize: "0.875rem",
                  color: charCount > 490 ? "red" : "gray",
                }}
              >
                {charCount}/500
              </div>
            </Form.Group>
            {errorMessage && (
              <div className="text-danger mt-2">{errorMessage}</div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeInquiryModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitInquiry}>
            Submit Inquiry
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showThankYouModal} onHide={closeThankYouModal} centered>
        <Modal.Header
          closeButton
          className="border-bottom-0 p-3"
        ></Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center text-center">
            {/* <img src={thankyou} className="mb-3 " alt="Thank You" /> */}
            <div className=" fs-5 thankyou mt-3">
              <span className="fs-4 fw-bold">Thank you</span> for sharing your
              feedback. We really appreciate it!
            </div>
            <div className="thankyou_para mt-3">
              Our team will revert back on your feedback soon.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top-0 mb-4 justify-content-center">
          <Button
            variant="primary"
            className="cbmo-outline-button"
            onClick={closeThankYouModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default Product;
